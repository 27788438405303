import React from "react"

import SiteLayout from "../../components/layout/site"

export default () => {
  return (
    <SiteLayout title="Support">
      <section className="section section-lg">
        <div className="container">
          <h1>Frequently Asked Questions</h1>
          <p className="text-muted">Please come back to check later
          </p>
        </div>
      </section>
    </SiteLayout>
  )
}
